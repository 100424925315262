<template>
  <dv-full-screen-container class="bg">
    <div class="box">
      <div style="padding-top: 2.5rem">
        <div class="inputBox">
          <img
            src="@/assets/home/loginInput.png"
            alt=""
            class="imgInput"
            v-if="inputShow"
          />
          <img
            src="../../assets/home/loginIcon1.png"
            alt=""
            class="loginImg"
            v-if="imgShow"
          />
          <img
            src="../../assets/home/loginIcon.png"
            alt=""
            class="loginImg"
            v-else
          />
          <el-input
            v-model="userName"
            placeholder="请输入内容"
            @focus="handleHead(0)"
            @blur="handleHead(1)"
          ></el-input>
        </div>
        <div class="inputBox">
          <img
            src="@/assets/home/loginInput.png"
            alt=""
            class="imgInput"
            v-if="inputShows"
          />
          <img
            src="@/assets/home/key.png"
            alt=""
            class="loginImg"
            v-if="keyShow"
          />
          <img src="@/assets/home/key1.png" alt="" class="loginImg" v-else />
          <el-input
            placeholder="请输入密码"
            v-model="userPassword"
            show-password
            @focus="handleKey(0)"
            @blur="handleKey(1)"
          ></el-input>
        </div>
        <div
          class="button"
          @click="handleOk"
          :class="okShow == true ? 'button' : 'buttons'"
        >
          登 录
        </div>
      </div>
    </div>
  </dv-full-screen-container>
</template>

<script>
import Header from "@/components/Header/header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      bg: require("../../assets/home/loginBg.png"),
      imgShow: true,
      userName: "",
      userPassword: "",
      keyShow: true,
      inputShow: false,
      inputShows: false,
      okShow: true,
    };
  },
  computed: {},
  created() {},
  methods: {
    handleHead(idx) {
      if (idx == 0) {
        this.imgShow = false;
        this.inputShow = true;
      } else {
        this.imgShow = true;
        this.inputShow = false;
      }
    },
    handleKey(idx) {
      if (idx == 0) {
        this.keyShow = false;
        this.inputShows = true;
      } else {
        this.keyShow = true;
        this.inputShows = false;
      }
    },
    handleOk() {
      this.okShow = !this.okShow;
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-image: url("../../assets/home/login.png");
  background-position: bottom center;
  background-size: 100% 100%;
}

.box {
  width: 6rem;
  height: 5.5rem;
  background-image: url("../../assets/home/loginBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: auto;
  margin-top: 1.5rem;
  .imgs {
    width: 1.15rem;
    height: 1.15rem;
  }
  h5 {
    color: #fff;
    font-size: 0.18rem;
    margin-top: 0.05rem;
  }
  .inputBox {
    padding: 0.1rem;
    position: relative;
    text-align: center;
    margin-bottom: 0.1rem;
    .imgInput {
      position: absolute;
      width: 4.3rem !important;
      height: 0.9rem;
      left: 0.85rem;
      top: -0.1rem;
    }
  }
  .loginImg {
    width: 0.28rem;
    height: 0.3rem;
    position: absolute;
    left: 1.3rem;
    top: 0.2rem;
    z-index: 10;
  }
  .button {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8rem;
    height: 0.5rem;
    font-size: 0.2rem;
    color: #005f62;
    font-weight: 700;
    margin-top: 0.2rem;
    background-image: url("../../assets/home/register.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .buttons {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.8rem;
    height: 0.5rem;
    font-size: 0.2rem;
    color: #fff;
    font-weight: 700;
    margin-top: 0.2rem;
    background-image: url("../../assets/home/register1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
</style>
<style lang="scss">
.box {
  .el-input__inner {
    width: 3.8rem !important;
    height: 0.5rem;
    border-radius: 0.1rem;
    border: 0;
    // background-image: url("../../assets/home/loginInput.png");
    // background-size: 100% auto;
    // background-repeat: no-repeat;
    background-color: #062635;
    padding-left: 0.8rem;
    color: #44f9ff;
    font-weight: 700;
  }
  .el-input__suffix {
    right: 1.5rem;
  }
}
</style>